import Utils from "../Utils";
import { valueTypes } from "./../Constants/Enums";
import { TimeSeriesEvent } from "./TimeSeriesEvent";
var EventsTableData = /** @class */ (function () {
    function EventsTableData() {
        this.columns = {};
        this.rows = [];
        this.events = [];
        this.timestampColumnKey = "timestamp ($ts)_DateTime";
        this.offsetName = null;
        this.maxVisibleToStart = 10;
        this.offsetNameCache = {};
        this.timeSeriesIdProperties = [];
    }
    EventsTableData.prototype.createOffsetName = function (offset) {
        if (offset in this.offsetNameCache) {
            return this.offsetNameCache[offset];
        }
        var offsetSubstring = "";
        if ((typeof offset) === 'string') {
            offsetSubstring = Utils.convertTimezoneToLabel(offset);
        }
        else {
            offsetSubstring = Utils.formatOffsetMinutes(offset);
        }
        var offsetName = "timestamp " + offsetSubstring;
        this.offsetNameCache[offset] = offsetName;
        return offsetName;
    };
    EventsTableData.prototype.sortColumnKeys = function () {
        var _this = this;
        var columnKeys = Object.keys(this.columns);
        var existingTsidColumnKeys = Object.values(this.columns).filter(function (c) { return c['isTsid']; }).map(function (c) { return c['key']; }); // detect existing time series id properties in column keys
        columnKeys = existingTsidColumnKeys.concat(columnKeys.filter(function (c) { return !existingTsidColumnKeys.includes(c); })); // put these time series id properties to the beginning of the column key list
        var offsetKey = this.offsetName + "_DateTime";
        var lessTimestamps = columnKeys.filter(function (columnKey) {
            return (columnKey !== _this.timestampColumnKey && columnKey !== offsetKey);
        });
        var timestamps = [];
        if (columnKeys.indexOf(this.timestampColumnKey) !== -1)
            timestamps.push(this.timestampColumnKey);
        if (columnKeys.indexOf(offsetKey) !== -1)
            timestamps.push(offsetKey);
        return timestamps.concat(lessTimestamps);
    };
    EventsTableData.prototype.setEvents = function (rawEvents, fromTsx, timeSeriesIdProperties, offset) {
        var _this = this;
        if (offset === void 0) { offset = null; }
        this.timeSeriesIdProperties = timeSeriesIdProperties;
        this.events = [];
        rawEvents.forEach(function (rawEvent) {
            if (!fromTsx) {
                rawEvent = Object.keys(rawEvent).reduce(function (newEventMap, currColName) {
                    newEventMap[currColName] = {
                        name: currColName,
                        value: rawEvent[currColName]
                    };
                    return newEventMap;
                }, {});
            }
            if (offset !== null) {
                _this.offsetName = _this.createOffsetName(offset);
            }
            var event = new TimeSeriesEvent(rawEvent, offset, (offset !== null ? _this.offsetName : null));
            _this.events.push(event);
        });
        this.constructColumns();
    };
    EventsTableData.prototype.sortEvents = function (columnKey, isAscending) {
        var sortType = this.columns[columnKey].type;
        var aTop = 1;
        var bTop = -1;
        if (!isAscending) {
            aTop = -1;
            bTop = 1;
        }
        this.events.sort(function (a, b) {
            if ((a.cells && a.cells[columnKey]) || (b.cells && b.cells[columnKey])) {
                var aConverted = (a.cells && a.cells[columnKey]) ? a.cells[columnKey].value : null;
                var bConverted = (b.cells && b.cells[columnKey]) ? b.cells[columnKey].value : null;
                //one value is null
                if (aConverted == null)
                    return bTop;
                if (bConverted == null)
                    return aTop;
                //convert to appropriate type
                if (sortType == "Double") {
                    aConverted = Number(aConverted);
                    bConverted = Number(bConverted);
                }
                else if (sortType == "DateTime") {
                    aConverted = (new Date(aConverted)).valueOf();
                    bConverted = (new Date(bConverted)).valueOf();
                }
                //compare
                if (aConverted > bConverted)
                    return aTop;
                if (aConverted < bConverted)
                    return bTop;
                return 0;
            }
            return 0;
        });
    };
    EventsTableData.prototype.constructColumns = function () {
        var _this = this;
        var timeSeriesIdPropertyKeys = this.timeSeriesIdProperties.map(function (p) { return p.name + "_" + p.type; });
        var newColumns = {};
        this.events.forEach(function (event) {
            Object.keys(event.cells).forEach(function (cellKey) {
                var cell = event.cells[cellKey];
                if (_this.columns[cell.key] == null) {
                    newColumns[cell.key] = {
                        key: cell.key,
                        name: cell.name,
                        visible: true,
                        type: cell.type,
                        isTsid: timeSeriesIdPropertyKeys.includes(cell.key)
                    };
                }
                else {
                    newColumns[cell.key] = _this.columns[cell.key];
                }
            });
        });
        var visibleColumnCounter = Object.values(newColumns).filter(function (c) { return c['isTsid']; }).length;
        Object.keys(newColumns).forEach(function (columnKey) {
            if (newColumns[columnKey].isTsid) {
                newColumns[columnKey].visible = true;
            }
            else {
                newColumns[columnKey].visible = visibleColumnCounter < _this.maxVisibleToStart;
                visibleColumnCounter++;
            }
        });
        this.columns = newColumns;
    };
    EventsTableData.prototype.generateCSVString = function (includeAllColumns, offset) {
        if (includeAllColumns === void 0) { includeAllColumns = true; }
        if (offset === void 0) { offset = 0; }
        //replace comma at end of line with end line character
        var endLine = function (s) {
            return s.slice(0, s.length - 1) + "\n";
        };
        var columnKeys = this.sortColumnKeys();
        var csvString = endLine(columnKeys.reduce(function (headerString, columnKey) {
            return headerString + Utils.sanitizeString(columnKey, valueTypes.String) + ",";
        }, ""));
        this.events.forEach(function (event) {
            csvString += endLine(columnKeys.reduce(function (lineString, columnKey) {
                var value = (event.cells[columnKey] ? (typeof (event.cells[columnKey].value) === 'function' ? event.cells[columnKey].value() : event.cells[columnKey].value) : null);
                return lineString + ((event.cells[columnKey] != null && Utils.sanitizeString(value, event.cells[columnKey].type) !== null) ?
                    Utils.sanitizeString(value, event.cells[columnKey].type) : "") + ",";
            }, ""));
        });
        return csvString;
    };
    return EventsTableData;
}());
export { EventsTableData };
