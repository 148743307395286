var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Utils from "../Utils";
import { ChartComponent } from "./ChartComponent";
import { ChartDataOptions } from "../Models/ChartDataOptions";
var ChartVisualizationComponent = /** @class */ (function (_super) {
    __extends(ChartVisualizationComponent, _super);
    function ChartVisualizationComponent(renderTarget) {
        return _super.call(this, renderTarget) || this;
    }
    ChartVisualizationComponent.prototype.render = function (data, options, aggregateExpressionOptions) {
        this.data = Utils.standardizeTSStrings(data);
        this.chartOptions.setOptions(options);
        this.aggregateExpressionOptions = data.map(function (d, i) {
            return Object.assign(d, aggregateExpressionOptions && i in aggregateExpressionOptions ?
                new ChartDataOptions(aggregateExpressionOptions[i]) :
                new ChartDataOptions({}));
        });
    };
    return ChartVisualizationComponent;
}(ChartComponent));
export { ChartVisualizationComponent };
