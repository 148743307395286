export var FrameType;
(function (FrameType) {
    FrameType["DataTable"] = "DataTable";
    FrameType["DataSetHeader"] = "DataSetHeader";
    FrameType["DataSetCompletion"] = "DataSetCompletion";
})(FrameType || (FrameType = {}));
var TableKind;
(function (TableKind) {
    TableKind["PrimaryResult"] = "PrimaryResult";
})(TableKind || (TableKind = {}));
/**
 * A simple holder class for the ADX Response data.
 *
 * @remarks
 * This class is designed to support the TSI Trender library, not to
 * provide a fully featured ADX client.
 *
 * @see {@link https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/ API Documentation}
 */
var ADXResponse = /** @class */ (function () {
    function ADXResponse(response) {
        this.dataTables = [];
        for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
            var frame = response_1[_i];
            switch (frame.FrameType) {
                case "DataTable":
                    this.dataTables.push(frame);
                    break;
                case "DataSetHeader":
                    this.dataSetHeader = frame;
                    break;
                case "DataSetCompletion":
                    this.dataSetCompletion = frame;
                    break;
            }
        }
    }
    Object.defineProperty(ADXResponse.prototype, "primaryTables", {
        /**
         * Getter for tables marked as primary. Used to easily filter out ADX metadata in the response.
         */
        get: function () {
            return this.dataTables.filter(function (table) { return table.TableKind == TableKind.PrimaryResult; });
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Finds the table with the provided name in the response
     * Use the `as` operator in KQL to name your tables
     *
     * @param tableName - A string to match each table name against
     * @returns The Raw table
     */
    ADXResponse.prototype.getTable = function (tableName) {
        var table = this.dataTables.find(function (table) { return table.TableName == tableName; });
        if (table) {
            return table;
        }
        throw new Error("Cannot find table " + tableName + " in response");
    };
    /**
     * Converts an ADX Table (columns, rows) into an array of keyed objects
     *
     * @param table - The raw ADX Data Table to convert
     * @returns An array of native keyed objects
     */
    ADXResponse.prototype.unfoldTable = function (table) {
        return table.Rows.map(function (row) {
            return table.Columns.reduce(function (obj, col, i) {
                obj[col.ColumnName] = row[i];
                return obj;
            }, {});
        });
    };
    return ADXResponse;
}());
export { ADXResponse };
