var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as d3 from "d3";
import "./ModelAutocomplete.scss";
import "awesomplete";
import { Component } from "../../Interfaces/Component";
import { ChartOptions } from "../../Models/ChartOptions";
import Utils from "../../Utils";
var ModelAutocomplete = /** @class */ (function (_super) {
    __extends(ModelAutocomplete, _super);
    function ModelAutocomplete(renderTarget, delegate) {
        var _this = _super.call(this, renderTarget) || this;
        _this.chartOptions = new ChartOptions(); // TODO handle onkeyup and oninput in chart options
        _this.delegate = delegate;
        return _this;
    }
    ModelAutocomplete.prototype.render = function (chartOptions) {
        var _this = this;
        this.chartOptions.setOptions(chartOptions);
        var targetElement = d3.select(this.renderTarget);
        targetElement.html("");
        var wrapper = targetElement
            .append("div")
            .attr("class", "tsi-modelAutocompleteWrapper");
        _super.prototype.themify.call(this, wrapper, this.chartOptions.theme);
        var inputWrapper = wrapper.append("div").attr("class", "tsi-search");
        inputWrapper.append("i").classed("tsi-search-icon", true);
        var input = inputWrapper
            .append("input")
            .attr("class", "tsi-searchInput")
            .attr("aria-label", this.getString("Search Time Series Instances"))
            .attr("aria-describedby", "tsi-search-desc")
            .attr("role", "combobox")
            .attr("aria-owns", "tsi-search-results")
            .attr("aria-expanded", "false")
            .attr("aria-haspopup", "listbox")
            .attr("placeholder", this.getString("Search Time Series Instances") + "...");
        var clear = inputWrapper
            .append("div")
            .attr("class", "tsi-clear")
            .attr("tabindex", "0")
            .attr("role", "button")
            .attr("aria-label", "Clear Search")
            .on("click keydown", function () {
            if (Utils.isKeyDownAndNotEnter(d3.event)) {
                return;
            }
            input.node().value = "";
            noSuggest = true;
            input.dispatch("input");
            self.ap.close();
            d3.select(this).classed("tsi-shown", false);
        });
        inputWrapper
            .append("span")
            .attr("id", "tsi-search-desc")
            .style("display", "none")
            .text(this.getString("Search suggestion instruction"));
        inputWrapper
            .append("div")
            .attr("class", "tsi-search-results-info")
            .attr("aria-live", "assertive");
        var Awesomplete = window.Awesomplete;
        this.ap = new Awesomplete(input.node(), { minChars: 1 });
        var noSuggest = false;
        var justAwesompleted = false;
        input.node().addEventListener("awesomplete-selectcomplete", function (event) {
            noSuggest = true;
            input.dispatch("input");
            _this.ap.close();
            justAwesompleted = true;
        });
        input.on("keydown", function () {
            _this.chartOptions.onKeydown(d3.event, _this.ap);
        });
        input.node().addEventListener("keyup", function (event) {
            if (justAwesompleted) {
                justAwesompleted = false;
                return;
            }
            var key = event.which || event.keyCode;
            if (key === 13) {
                noSuggest = true;
                input.dispatch("input");
            }
        });
        var searchText;
        var self = this;
        input.on("input", function () {
            return __awaiter(this, void 0, void 0, function () {
                var suggestions, liveAria_1, event;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            searchText = this.value;
                            if (!(searchText.replace(/ /g, "") && !noSuggest)) return [3 /*break*/, 2];
                            return [4 /*yield*/, self.delegate.getInstancesSuggestions(searchText)];
                        case 1:
                            suggestions = _a.sent();
                            self.ap.list = suggestions.map(function (s) { return s.searchString; });
                            self.ap.ul.setAttribute("role", "listbox");
                            self.ap.ul.setAttribute("tabindex", "0");
                            self.ap.ul.setAttribute("id", "tsi-search-results");
                            self.ap.ul.querySelectorAll("li").forEach(function (li) {
                                li.setAttribute("role", "option");
                                li.setAttribute("tabindex", "-1");
                            });
                            liveAria_1 = document.getElementsByClassName("tsi-search-results-info")[0];
                            liveAria_1.innerText =
                                self.ap.suggestions && self.ap.suggestions.length
                                    ? self.ap.suggestions.length +
                                        self.getString("Search suggestions available")
                                    : self.getString("No results");
                            setTimeout(function () {
                                liveAria_1.innerText = "";
                            }, 1000);
                            return [3 /*break*/, 3];
                        case 2:
                            self.ap.close();
                            _a.label = 3;
                        case 3:
                            event = d3.event;
                            self.chartOptions.onInput(searchText, noSuggest || !event ? { which: 13 } : d3.event);
                            noSuggest = false;
                            clear.classed("tsi-shown", searchText.length);
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    return ModelAutocomplete;
}(Component));
export default ModelAutocomplete;
