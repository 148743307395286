import Utils from "../Utils";
import { TimeSeriesEventCell } from "./TimeSeriesEventCell";
var TimeSeriesEvent = /** @class */ (function () {
    function TimeSeriesEvent(rawEvent, offset, offsetName, locale) {
        if (offset === void 0) { offset = null; }
        if (offsetName === void 0) { offsetName = null; }
        if (locale === void 0) { locale = 'en'; }
        this.cells = {};
        if (offset !== null) {
            var type = 'DateTime';
            var utcOffsetDate_1 = Utils.offsetUTC(new Date(Date.parse(rawEvent['timestamp ($ts)'].split("Z").join(""))));
            rawEvent[offsetName + "_" + type] = {
                name: offsetName,
                value: function () { return Utils.timeFormat(true, true, offset, true, null, null, locale)(utcOffsetDate_1); },
                type: type
            };
        }
        this.cells = Object.keys(rawEvent).reduce(function (cellObj, propId) {
            var cell;
            if (propId == "timestamp ($ts)")
                cell = new TimeSeriesEventCell('timestamp ($ts)', rawEvent[propId], 'DateTime');
            else {
                cell = new TimeSeriesEventCell(rawEvent[propId]['name'], rawEvent[propId]['value'], rawEvent[propId]['type']);
            }
            cellObj[cell.key] = cell;
            return cellObj;
        }, {});
    }
    return TimeSeriesEvent;
}());
export { TimeSeriesEvent };
