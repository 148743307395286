var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import './DateTimeButtonSingle.scss';
import { DateTimeButton } from '../../Interfaces/DateTimeButton';
import Utils from '../../Utils';
import SingleDateTimePicker from '../SingleDateTimePicker/SingleDateTimePicker';
var DateTimeButtonSingle = /** @class */ (function (_super) {
    __extends(DateTimeButtonSingle, _super);
    function DateTimeButtonSingle(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.sDTPOnSet = function (millis) {
            if (millis === void 0) { millis = null; }
            if (millis !== null) {
                _this.dateTimeButton.text(_this.buttonDateTimeFormat(millis));
                _this.selectedMillis = millis;
                _this.onSet(millis);
            }
            _this.closeSDTP();
        };
        return _this;
    }
    DateTimeButtonSingle.prototype.closeSDTP = function () {
        this.dateTimePickerContainer.style("display", "none");
        this.dateTimeButton.node().focus();
    };
    DateTimeButtonSingle.prototype.render = function (chartOptions, minMillis, maxMillis, selectedMillis, onSet) {
        var _this = this;
        if (chartOptions === void 0) { chartOptions = {}; }
        if (selectedMillis === void 0) { selectedMillis = null; }
        if (onSet === void 0) { onSet = null; }
        _super.prototype.render.call(this, chartOptions, minMillis, maxMillis, onSet);
        this.selectedMillis = selectedMillis;
        d3.select(this.renderTarget).classed('tsi-dateTimeContainerSingle', true);
        this.dateTimeButton.text(this.buttonDateTimeFormat(selectedMillis));
        if (!this.dateTimePicker) {
            this.dateTimePicker = new SingleDateTimePicker(this.dateTimePickerContainer.node());
        }
        var targetElement = d3.select(this.renderTarget);
        var dateTimeTextChildren = (targetElement.select(".tsi-dateTimePickerContainer")).selectAll("*");
        d3.select("html").on("click." + Utils.guid(), function () {
            var pickerContainerChildren = _this.dateTimePickerContainer.selectAll("*");
            var outside = dateTimeTextChildren.filter(Utils.equalToEventTarget).empty()
                && targetElement.selectAll(".tsi-dateTimePickerContainer").filter(Utils.equalToEventTarget).empty()
                && targetElement.selectAll(".tsi-dateTimeButton").filter(Utils.equalToEventTarget).empty()
                && targetElement.selectAll(".tsi-saveButton").filter(Utils.equalToEventTarget).empty();
            var inClickTarget = pickerContainerChildren.filter(Utils.equalToEventTarget).empty();
            if (outside && inClickTarget && (_this.dateTimePickerContainer.style('display') !== 'none')) {
                _this.closeSDTP();
            }
        });
        this.dateTimeButton.on("click", function () {
            _this.chartOptions.dTPIsModal = true;
            _this.dateTimePickerContainer.style("display", "block");
            _this.dateTimePicker.render(_this.chartOptions, _this.minMillis, _this.maxMillis, _this.selectedMillis, _this.sDTPOnSet);
        });
    };
    return DateTimeButtonSingle;
}(DateTimeButton));
export default DateTimeButtonSingle;
