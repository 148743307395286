var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HierarchiesExpandKind, } from "./ADXTrenderClient";
var typeId = "ADX-TYPE-ID";
function highlight(value, search) {
    return value.replace(search, "<hit>" + search + "</hit>");
}
function tagToHit(tag, search) {
    var id = tag.TimeseriesId == search ? "<hit>" + tag.TimeseriesId + "</hit>" : tag.TimeseriesId;
    return {
        timeSeriesId: [tag.TimeseriesId],
        typeId: typeId,
        name: tag.DisplayName,
        highlights: {
            timeSeriesId: [id],
            description: tag.Path ? tag.Path[tag.Path.length - 1] : undefined,
            name: highlight(tag.DisplayName, search),
        },
    };
}
function convertHierarchy(h) {
    var children = Object.values(h.children);
    var out = {
        name: h.name,
        cumulativeInstanceCount: h.hits,
    };
    if (children.length) {
        out.hierarchyNodes = {
            hitCount: children.length,
            hits: children.map(convertHierarchy)
        };
    }
    return out;
}
/**
 * A helper that wraps {@link ADXTrenderClient} to help with hierarchy navigation.
 * This class can be extended to modify the hierarchy component's behavior.
 */
var HierarchyDelegate = /** @class */ (function () {
    function HierarchyDelegate(client) {
        this.client = client;
    }
    HierarchyDelegate.prototype.getTimeSeriesTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log("getTimeSeriesTypes");
                return [2 /*return*/, [
                        {
                            id: typeId,
                            name: "DefaultType",
                            description: "Default type",
                            variables: {
                                EventCount: { kind: "aggregate", aggregation: { tsx: "count()" } },
                            },
                        },
                    ]];
            });
        });
    };
    HierarchyDelegate.prototype.getHierarchies = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.getHierarchies()];
                    case 1: return [2 /*return*/, (_a.sent()).map(function (h) { return ({
                            name: h.HierarchyName,
                            id: h.HierarchyName,
                        }); })];
                }
            });
        });
    };
    HierarchyDelegate.prototype.getInstancesSuggestions = function (text) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.getSuggestions(text)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.map(function (searchString) { return ({ searchString: searchString }); })];
                }
            });
        });
    };
    HierarchyDelegate.prototype.getInstancesPathSearch = function (payload) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, tableName, tagsTableName, query, result_1, unfoldedHierarchy, fullHierarchy_1, unfoldedTags, _c;
            var _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        result = {
                            children: [],
                            tags: [],
                        };
                        if (!(payload.searchString &&
                            ((_b = (_a = payload.hierarchies) === null || _a === void 0 ? void 0 : _a.expand) === null || _b === void 0 ? void 0 : _b.kind) == HierarchiesExpandKind.UntilChildren)) return [3 /*break*/, 2];
                        tableName = "TrenderHierarchySearch";
                        tagsTableName = "HierarchyTags";
                        query = "\n      declare query_parameters(SearchString:string, Path: dynamic);\n      GetPathToTimeseriesId(Path, SearchString) | as " + tableName + ";\n      Search_ExactPath(Path, SearchString) | as " + tagsTableName + ";\n    ";
                        return [4 /*yield*/, this.client.executeQuery(query, {
                                parameters: {
                                    Path: "dynamic(" + JSON.stringify(payload.path) + ")",
                                    SearchString: payload.searchString,
                                },
                            })];
                    case 1:
                        result_1 = _f.sent();
                        unfoldedHierarchy = result_1.unfoldTable(result_1.getTable(tableName));
                        fullHierarchy_1 = {
                            hits: 1,
                            name: "ROOT",
                            children: {},
                        };
                        unfoldedHierarchy.forEach(function (hit) {
                            var path = hit.Path;
                            var pointer = fullHierarchy_1;
                            for (var _i = 0, _a = payload.path; _i < _a.length; _i++) {
                                var element = _a[_i];
                                if (path[0] == element) {
                                    path.shift();
                                }
                                else {
                                    break;
                                }
                            }
                            path.forEach(function (level) {
                                if (level in pointer.children) {
                                    pointer.children[level].hits++;
                                }
                                else {
                                    pointer.children[level] = {
                                        hits: 1,
                                        name: level,
                                        children: {},
                                    };
                                }
                                pointer = pointer.children[level];
                            });
                        });
                        unfoldedTags = result_1.unfoldTable(result_1.getTable(tagsTableName));
                        return [2 /*return*/, {
                                instances: {
                                    hits: unfoldedTags.map(function (tag) { return tagToHit(tag, payload.searchString); }),
                                    hitCount: unfoldedTags.length,
                                },
                                hierarchyNodes: convertHierarchy(fullHierarchy_1).hierarchyNodes,
                            }];
                    case 2:
                        if (!(payload.searchString && payload.instances.recursive)) return [3 /*break*/, 4];
                        _c = result;
                        return [4 /*yield*/, this.client.getChildrenTags(payload)];
                    case 3:
                        _c.tags = _f.sent();
                        return [3 /*break*/, 10];
                    case 4:
                        if (!payload.searchString) return [3 /*break*/, 6];
                        console.log("level");
                        _d = {
                            children: []
                        };
                        return [4 /*yield*/, this.client.searchTagsAtPath(payload)];
                    case 5:
                        result = (_d.tags = _f.sent(),
                            _d);
                        return [3 /*break*/, 10];
                    case 6:
                        if (!payload.searchString) return [3 /*break*/, 8];
                        console.log("level");
                        _e = {
                            children: []
                        };
                        return [4 /*yield*/, this.client.searchTagsAtPath(payload)];
                    case 7:
                        result = (_e.tags = _f.sent(),
                            _e);
                        return [3 /*break*/, 10];
                    case 8: return [4 /*yield*/, this.client.getHierarchyLevel(payload)];
                    case 9:
                        result = _f.sent();
                        _f.label = 10;
                    case 10: return [2 /*return*/, {
                            hierarchyNodes: {
                                hits: result.children.map(function (child) { return ({
                                    name: child.Child,
                                    cumulativeInstanceCount: child.Count,
                                }); }),
                                hitCount: result.children.length,
                            },
                            instances: {
                                hits: result.tags.map(function (tag) { return tagToHit(tag, payload.searchString); }),
                                hitCount: result.tags.length,
                            },
                        }];
                }
            });
        });
    };
    HierarchyDelegate.prototype.getInstancesSearch = function (text) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log("getInstancesSearch", text);
                return [2 /*return*/, {
                        instances: {
                            hits: [
                                {
                                    timeSeriesId: ["06d862a1-ee99-40ca-94c3-83659ad8add0"],
                                    typeId: "f325dd8f-cabd-4c4b-a092-9e592aaba440",
                                    hierarchyIds: ["33d72529-dd73-4c31-93d8-ae4e6cb5605d"],
                                    highlights: {
                                        timeSeriesId: [
                                            "<hit>06d862a1</hit>-<hit>ee99</hit>-<hit>40ca</hit>-<hit>94c3</hit>-<hit>83659ad8add0</hit>",
                                        ],
                                        typeName: "WeatherSensor",
                                        name: "",
                                        description: "ContosoFarm1W6_WindSpeed1",
                                        hierarchyIds: ["33d72529-dd73-4c31-93d8-ae4e6cb5605d"],
                                        hierarchyNames: ["Contoso WindFarm Hierarchy"],
                                        instanceFieldNames: ["Name", "Plant", "Unit", "System"],
                                        instanceFieldValues: [
                                            "WindSpeed",
                                            "Contoso Plant 1",
                                            "W6",
                                            "Weather System",
                                        ],
                                    },
                                },
                            ],
                            hitCount: 1,
                        },
                    }];
            });
        });
    };
    /*
  {
    "searchString": "",
    "path": [],
    "instances": {
      "recursive": false,
      "sort": {
        "by": "DisplayName"
      },
      "highlights": true,
      "pageSize": 10
    },
    "hierarchies": {
      "expand": {
        "kind": "OneLevel"
      },
      "sort": {
        "by": "Name"
      },
      "pageSize": 10
    }
  }
    */
    HierarchyDelegate.prototype.getInstances = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log("getInstances");
                throw "";
            });
        });
    };
    return HierarchyDelegate;
}());
export { HierarchyDelegate };
