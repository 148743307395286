var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ADXClient } from "./ADXClient";
export var HierarchiesExpandKind;
(function (HierarchiesExpandKind) {
    HierarchiesExpandKind["OneLevel"] = "OneLevel";
    HierarchiesExpandKind["UntilChildren"] = "UntilChildren";
})(HierarchiesExpandKind || (HierarchiesExpandKind = {}));
/**
 * A helper subclass of {@link ADXClient} with trender-specific methods.
 */
var ADXTrenderClient = /** @class */ (function (_super) {
    __extends(ADXTrenderClient, _super);
    function ADXTrenderClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns availability information in a trender-friendly format.
     *
     * @remarks This function always returns a one hour interval.
     */
    ADXTrenderClient.prototype.getAvailability = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tableName, result, rows, from, to, values;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableName = "TrenderAvailability";
                        return [4 /*yield*/, this.executeQuery("GetTotalAvailability() | as " + tableName)];
                    case 1:
                        result = _a.sent();
                        rows = result.getTable(tableName).Rows;
                        if (rows.length == 0) {
                            throw new Error("Availability array is empty.");
                        }
                        from = rows[0][0];
                        to = from;
                        values = rows.reduce(function (obj, val) {
                            var date = val[0].toString();
                            if (date < from)
                                from = date;
                            if (date > to)
                                to = date;
                            obj[date] = { count: val[1] };
                            return obj;
                        }, {});
                        return [2 /*return*/, {
                                availability: [{ availabilityCount: { "": values } }],
                                range: {
                                    to: to,
                                    from: from,
                                },
                            }];
                }
            });
        });
    };
    /**
     * Returns trender instances
     */
    ADXTrenderClient.prototype.getInstances = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tableName, result, rows;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableName = "TrenderInstances";
                        return [4 /*yield*/, this.executeQuery("GetInstances() | as " + tableName)];
                    case 1:
                        result = _a.sent();
                        rows = result.unfoldTable(result.getTable(tableName));
                        if (rows.length == 0) {
                            throw new Error("Instances array is empty.");
                        }
                        return [2 /*return*/, rows];
                }
            });
        });
    };
    /**
     * Returns an aggregate over time for the provided tags and time interval.
     */
    ADXTrenderClient.prototype.getAggregates = function (tags, startDate, endDate, interval) {
        return __awaiter(this, void 0, void 0, function () {
            var aggregatesTableName, metadataTableName, result, table, metadataTable, metadata, headers, grouped, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        aggregatesTableName = "TrenderAggregates";
                        metadataTableName = "TrenderMetadata";
                        return [4 /*yield*/, this.executeQuery("declare query_parameters(TimeSeries:dynamic,StartTime:datetime,EndTime:datetime,Interval:timespan);" +
                                ("GetAggregates(TimeSeries, StartTime, EndTime, Interval) | order by TimeseriesId | as " + aggregatesTableName + ";\n      GetMetaData(TimeSeries) | as " + metadataTableName), {
                                parameters: {
                                    TimeSeries: "dynamic(" + JSON.stringify(tags) + ")",
                                    StartTime: startDate,
                                    EndTime: endDate,
                                    Interval: interval,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        table = result.getTable(aggregatesTableName);
                        metadataTable = result.getTable(metadataTableName);
                        if (table.Rows.length == 0) {
                            throw new Error("Aggregates array is empty.");
                        }
                        if (metadataTable.Rows.length == 0) {
                            throw new Error("Aggregates array is empty.");
                        }
                        metadata = result.unfoldTable(metadataTable).reduce(function (col, tag) {
                            col[tag.TimeseriesId] = tag;
                            return col;
                        }, {});
                        headers = table.Columns.map(function (c) { return c.ColumnName; });
                        headers.splice(0, 2);
                        grouped = table.Rows.reduce(function (prev, curr) {
                            var id = curr.shift();
                            var timestamp = curr.shift();
                            var name = metadata[id].TimeseriesName;
                            var group = metadata[id].Path.slice(-1);
                            if (!prev.hasOwnProperty(group)) {
                                prev[group] = {};
                            }
                            if (!prev[group].hasOwnProperty(name)) {
                                prev[group][name] = {};
                            }
                            prev[group][name][timestamp] = headers.reduce(function (obj, col, i) {
                                obj[col] = curr[i];
                                return obj;
                            }, {});
                            return prev;
                        }, {});
                        console.log(grouped);
                        return [2 /*return*/, Object.keys(grouped).map(function (key) {
                                var _a;
                                return _a = {},
                                    _a[key] = grouped[key],
                                    _a;
                            })];
                }
            });
        });
    };
    // #region Hierarch
    /**
     * Returns available hierarchies.
     */
    ADXTrenderClient.prototype.getHierarchies = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tableName, result, rows;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableName = "HierarchyList";
                        return [4 /*yield*/, this.executeQuery("GetHierarchies() | as " + tableName)];
                    case 1:
                        result = _a.sent();
                        rows = result.unfoldTable(result.getTable(tableName));
                        if (rows.length == 0) {
                            throw new Error("Hierarchies array is empty.");
                        }
                        return [2 /*return*/, rows];
                }
            });
        });
    };
    ADXTrenderClient.prototype.getHierarchyLevel = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var childrenTableName, tagsTableName, query, result, tags, children;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        childrenTableName = "HierarchyChildren";
                        tagsTableName = "HierarchyTags";
                        query = "\n      declare query_parameters(Path: dynamic);\n      GetTimeseriesIdByPath(Path) | as " + tagsTableName + ";\n      GetChildrenByPath(Path) | as " + childrenTableName + ";\n    ";
                        return [4 /*yield*/, this.executeQuery(query, {
                                parameters: {
                                    Path: "dynamic(" + JSON.stringify(search.path) + ")",
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        tags = result.unfoldTable(result.getTable(tagsTableName));
                        children = result.unfoldTable(result.getTable(childrenTableName));
                        return [2 /*return*/, {
                                tags: tags,
                                children: children,
                            }];
                }
            });
        });
    };
    ADXTrenderClient.prototype.searchTagsAtPath = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var tagsTableName, query, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tagsTableName = "HierarchyTags";
                        query = "\n      declare query_parameters(Path: dynamic, Search: string);\n      SearchTagsAtPath_Henning(Path, Search) | as " + tagsTableName + ";\n    ";
                        return [4 /*yield*/, this.executeQuery(query, {
                                parameters: {
                                    Path: "dynamic(" + JSON.stringify(payload.path) + ")",
                                    Search: payload.searchString,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.unfoldTable(result.getTable(tagsTableName))];
                }
            });
        });
    };
    ADXTrenderClient.prototype.getChildrenTags = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var tagsTableName, query, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tagsTableName = "HierarchyTags";
                        query = "\n      declare query_parameters(Path: dynamic, Search: string);\n      Search(Path, Search)\n      | as " + tagsTableName + ";\n    ";
                        return [4 /*yield*/, this.executeQuery(query, {
                                parameters: {
                                    Path: "dynamic(" + JSON.stringify(search.path) + ")",
                                    Search: search.searchString,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.unfoldTable(result.getTable(tagsTableName))];
                }
            });
        });
    };
    /**
     * Get suggested tag names for a search string.
     */
    ADXTrenderClient.prototype.getSuggestions = function (searchString) {
        return __awaiter(this, void 0, void 0, function () {
            var tableName, query, result, rows;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableName = "TrenderSuggestions";
                        query = "\n    declare query_parameters(SearchString:string);\n    Suggest(SearchString) | as " + tableName + "\n    ";
                        return [4 /*yield*/, this.executeQuery(query, {
                                parameters: { SearchString: searchString },
                            })];
                    case 1:
                        result = _a.sent();
                        rows = result.getTable(tableName).Rows.map(function (row) { return row[0]; });
                        return [2 /*return*/, rows];
                }
            });
        });
    };
    return ADXTrenderClient;
}(ADXClient));
export { ADXTrenderClient };
