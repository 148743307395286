import UXClient from "./UXClient/UXClient";
import Utils from "./UXClient/Utils";
import * as ADXClasses from "./ServerClient";
var KustoTrender = /** @class */ (function () {
    function KustoTrender() {
        this.ux = new UXClient();
        this.utils = Utils;
    }
    return KustoTrender;
}());
export default KustoTrender;
window.KustoTrender = KustoTrender;
window.ADX = ADXClasses;
