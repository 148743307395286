var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import Utils from "../Utils";
import { ChartComponent } from "./ChartComponent";
var DateTimeButton = /** @class */ (function (_super) {
    __extends(DateTimeButton, _super);
    function DateTimeButton(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.pickerIsVisible = false;
        return _this;
    }
    DateTimeButton.prototype.buttonDateTimeFormat = function (millis) {
        return Utils.timeFormat(!this.chartOptions.minutesForTimeLabels, !this.chartOptions.minutesForTimeLabels, this.chartOptions.offset, this.chartOptions.is24HourTime, 0, null, this.chartOptions.dateLocale)(millis);
    };
    DateTimeButton.prototype.render = function (chartOptions, minMillis, maxMillis, onSet) {
        if (onSet === void 0) { onSet = null; }
        var self = this;
        this.chartOptions.setOptions(chartOptions);
        this.minMillis = minMillis;
        this.maxMillis = maxMillis;
        this.onSet = onSet ? onSet : function () { };
        var dateTimeContainer = d3.select(this.renderTarget);
        if (!this.dateTimeButton) {
            this.dateTimeButton = dateTimeContainer.append("button")
                .classed('tsi-dateTimeButton', true);
        }
        if (!this.dateTimePickerContainer) {
            this.dateTimePickerContainer = dateTimeContainer.append('div').classed('tsi-dateTimePickerContainer', true)
                .attr('role', 'dialog')
                .attr('aria-label', this.getString('a time selection control dialog'));
            this.dateTimePickerContainer.style('display', 'none');
        }
        _super.prototype.themify.call(this, d3.select(this.renderTarget), this.chartOptions.theme);
    };
    return DateTimeButton;
}(ChartComponent));
export { DateTimeButton };
