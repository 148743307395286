var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import { Plot } from '../../Interfaces/Plot';
import Utils from '../../Utils';
import { DataTypes } from "../../Constants/Enums";
import { NONNUMERICTOPMARGIN, LINECHARTTOPPADDING } from "../../Constants/Constants";
var TOPMARGIN = 4;
var CategoricalPlot = /** @class */ (function (_super) {
    __extends(CategoricalPlot, _super);
    function CategoricalPlot(svgSelection) {
        var _this = _super.call(this, svgSelection) || this;
        _this.plotDataType = DataTypes.Categorical;
        return _this;
    }
    CategoricalPlot.prototype.onMouseover = function (d, rectWidth) {
        var _this = this;
        var visibleMeasures = this.getVisibleMeasures(d.measures);
        this.hoverRect.attr('visibility', 'visible')
            .attr('x', function () {
            return _this.x(new Date(d.dateTime));
        })
            .attr('width', rectWidth)
            .attr('height', Math.max(0, this.chartHeight + 1 - LINECHARTTOPPADDING))
            .attr('fill', function () {
            return visibleMeasures.length === 1 ? _this.getColorForValue(visibleMeasures[0]) : 'none';
        });
    };
    CategoricalPlot.prototype.onMouseout = function () {
        this.hoverRect.attr('visibility', 'hidden');
        this.categoricalMouseout();
    };
    CategoricalPlot.prototype.createHoverRect = function () {
        if (!this.hoverRect) {
            this.hoverRect = this.chartGroup.append('rect')
                .attr('class', 'tsi-categoricalHoverRect')
                .attr('y', LINECHARTTOPPADDING)
                .attr('height', this.chartHeight + 1);
        }
        else {
            this.hoverRect.raise();
        }
    };
    CategoricalPlot.prototype.getSeriesEndDate = function () {
        if (this.chartDataOptions.searchSpan) {
            return new Date(this.chartDataOptions.searchSpan.to);
        }
        return new Date(this.chartComponentData.toMillis);
    };
    CategoricalPlot.prototype.getBucketEndDate = function (d, i) {
        var _this = this;
        var data = this.chartComponentData.timeArrays[d.aggregateKey][d.splitBy];
        if (i + 1 < data.length) {
            return data[i + 1].dateTime;
        }
        else {
            var shouldRoundEnd = Utils.safeNotNullOrUndefined(function () { return _this.chartDataOptions.searchSpan; }) && Utils.safeNotNullOrUndefined(function () { return _this.chartDataOptions.searchSpan.bucketSize; });
            return shouldRoundEnd ? Utils.roundToMillis(this.getSeriesEndDate().valueOf(), Utils.parseTimeInput(this.chartDataOptions.searchSpan.bucketSize)) : this.getSeriesEndDate();
        }
    };
    CategoricalPlot.prototype.render = function (chartOptions, visibleAggI, agg, aggVisible, aggregateGroup, chartComponentData, yExtent, chartHeight, visibleAggCount, colorMap, previousAggregateData, x, areaPath, strokeOpacity, y, yMap, defs, chartDataOptions, previousIncludeDots, yTopAndHeight, chartGroup, categoricalMouseover, categoricalMouseout) {
        var _this = this;
        this.chartOptions = chartOptions;
        this.yTop = yTopAndHeight[0];
        this.height = yTopAndHeight[1];
        this.x = x;
        this.chartComponentData = chartComponentData;
        var aggKey = agg.aggKey;
        this.chartDataOptions = chartDataOptions;
        this.chartHeight = chartHeight;
        this.chartGroup = chartGroup;
        this.categoricalMouseover = categoricalMouseover;
        this.aggregateGroup = aggregateGroup;
        this.categoricalMouseout = categoricalMouseout;
        this.createBackdropRect(true);
        if (this.aggregateGroup.selectAll('defs').empty()) {
            this.defs = this.aggregateGroup.append('defs');
        }
        if (this.aggregateGroup.selectAll('.tsi-splitBysGroup').empty()) {
            this.splitBysGroup = this.aggregateGroup.append('g').classed('tsi-splitBysGroup', true);
        }
        var gradientData = [];
        var durationFunction = function (d) {
            var previousUndefined = previousAggregateData.get(_this) === undefined;
            return (self.chartOptions.noAnimate || previousUndefined) ? 0 : self.TRANSDURATION;
        };
        var self = this;
        this.createHoverRect();
        var series = this.getVisibleSeries(aggKey);
        var heightPerSeries = Math.max((self.chartDataOptions.height - (series.length * TOPMARGIN)) / series.length, 0);
        var splitByGroups = this.splitBysGroup.selectAll(".tsi-splitByGroup")
            .data(series, function (d) {
            return d.splitBy;
        });
        splitByGroups.enter()
            .append("g")
            .attr("class", "tsi-splitByGroup " + agg.aggKey)
            .merge(splitByGroups)
            .attr('transform', function (d, i) {
            return 'translate(0,' + (NONNUMERICTOPMARGIN + (i * (_this.chartDataOptions.height / series.length))) + ')';
        })
            .each(function (splitBy, j) {
            var data = self.chartComponentData.timeArrays[aggKey][splitBy];
            var categoricalBuckets = d3.select(this).selectAll(".tsi-categoricalBucket")
                .data(data);
            var getWidth = function (d, i) {
                var seriesWidth = self.x.range()[1] - self.x.range()[0];
                var xPos1 = Math.max(self.x(new Date(d.dateTime)), 0);
                var xPos2 = self.x(self.getBucketEndDate(d, i));
                return Math.max(xPos2 - xPos1, 1);
            };
            categoricalBuckets.enter()
                .append("rect")
                .attr("class", "tsi-valueElement tsi-categoricalBucket")
                .merge(categoricalBuckets)
                .style("visibility", function (d) {
                return (self.chartComponentData.isSplitByVisible(aggKey, splitBy) && self.hasData(d)) ? "visible" : "hidden";
            })
                .on('mouseover', function (d, i) {
                var y = self.yTop + (j * (self.chartDataOptions.height / series.length));
                var x = self.x(new Date(d.dateTime)) + (getWidth(d, i));
                var shouldMouseover = self.categoricalMouseover(d, x, y + NONNUMERICTOPMARGIN, self.getBucketEndDate(d, i), getWidth(d, i));
                if (shouldMouseover) {
                    self.onMouseover(d, getWidth(d, i));
                }
            })
                .on('mouseout', function () {
                self.onMouseout();
            })
                .attr('cursor', (self.chartDataOptions.onElementClick ? 'pointer' : 'inherit'))
                .on('click', function (d) {
                if (self.chartDataOptions.onElementClick) {
                    self.chartDataOptions.onElementClick(d.aggregateKey, d.splitBy, d.dateTime.toISOString(), d.measures);
                }
            })
                .transition()
                .duration(durationFunction)
                .ease(d3.easeExp)
                .attr('height', heightPerSeries)
                .attr('width', getWidth)
                .attr('x', function (d) {
                return self.x(new Date(d.dateTime));
            })
                .each(function (d, i) {
                var gradientKey = self.createGradientKey(d, j, i);
                gradientData.push([gradientKey, d]);
                d3.select(this)
                    .attr('fill', "url(#" + gradientKey + ")");
            });
            categoricalBuckets.exit().remove();
        });
        splitByGroups.exit().remove();
        //corresponding linear gradients
        var gradients = this.defs.selectAll('linearGradient')
            .data(gradientData, function (d) {
            return d[1].splitBy;
        });
        var enteredGradients = gradients.enter()
            .append('linearGradient')
            .attr("x2", "0%")
            .attr("y2", "100%")
            .merge(gradients)
            .attr("id", function (d) { return d[0]; });
        enteredGradients
            .each(function (d) {
            self.addGradientStops(d[1], d3.select(this));
        });
        gradients.exit().remove();
    };
    return CategoricalPlot;
}(Plot));
export default CategoricalPlot;
