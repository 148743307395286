import Utils from "../Utils";
var HierarchyNode = /** @class */ (function () {
    function HierarchyNode(name, level) {
        this.children = [];
        this.isExpanded = false;
        this.isSelected = false;
        this.isLeaf = false;
        this.childrenInFilter = false;
        this.selfInFilter = false;
        this.color = function () { return null; };
        this.click = function (n) { };
        this.isLeafParent = false; // used in the event of context menut to denote that we should use a context menu for children
        this.name = name;
        this.level = level;
        this.markedName = name;
    }
    HierarchyNode.prototype.filter = function (filterText) {
        var regExp = new RegExp(filterText, 'i');
        var isInFilter = function (node) {
            var childrenInFilter = node.children.reduce(function (p, c) {
                p = isInFilter(c) || p;
                return p;
            }, false);
            var selfInFilter = regExp.test(node.name);
            node.markedName = selfInFilter ? Utils.mark(filterText, node.name) : node.name;
            if (node.parent != null)
                node.parent.childrenInFilter = (selfInFilter || childrenInFilter) && filterText.length > 0;
            node.selfInFilter = selfInFilter && filterText.length > 0;
            node.childrenInFilter = childrenInFilter && filterText.length > 0;
            return childrenInFilter || selfInFilter;
        };
        isInFilter(this);
    };
    HierarchyNode.prototype.traverse = function (condition) {
        var traversal = [];
        if (condition(this))
            traversal.push(this);
        this.children.forEach(function (n) {
            traversal = traversal.concat(n.traverse(condition));
        });
        return traversal;
    };
    HierarchyNode.prototype.colorify = function (el) {
        if (this.isLeaf && this.isSelected && this.color(this))
            el.style('background-color', this.color(this));
        if (!this.isSelected && this.isLeaf)
            el.style('background-color', null);
    };
    return HierarchyNode;
}());
export { HierarchyNode };
