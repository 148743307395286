var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ADXResponse } from "./ADXResponse";
/**
 * A basic JS wrapper for the ADX Kusto REST Query APIs.
 *
 * @remarks
 * This class is designed to support the TSI Trender library, not to
 * provide a fully featured ADX client.
 *
 * @see {@link https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/ API Documentation}
 */
var ADXClient = /** @class */ (function () {
    function ADXClient(clusterUrl, tokenProvider, database) {
        this.clusterUrl = clusterUrl;
        this.database = database;
        this.tokenProvider = tokenProvider;
    }
    Object.defineProperty(ADXClient.prototype, "queryUrl", {
        /**
         * Getter for the query rest endpoint Url
         */
        get: function () {
            return this.clusterUrl + "/v2/rest/query";
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Executes a KQL query against the configured ADX cluster.
     *
     * @param query - KQL query
     * @param properties - Optional
     * @returns A Promise with an ADXRespnse object
     */
    ADXClient.prototype.executeQuery = function (query, properties) {
        return __awaiter(this, void 0, void 0, function () {
            var token, response, body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tokenProvider()];
                    case 1:
                        token = _a.sent();
                        body = {
                            db: this.database,
                            csl: query,
                            properties: __assign({}, properties),
                        };
                        return [4 /*yield*/, fetch(this.queryUrl, {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + token,
                                    "x-ms-client-request-id": "KTrender;" + self.crypto.randomUUID(),
                                    "x-ms-app": "KustoTrender"
                                },
                                body: JSON.stringify(body),
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) {
                            throw Error("Query call failed with status: " + response.status);
                        }
                        return [2 /*return*/, this.parseResponse(response)];
                }
            });
        });
    };
    ADXClient.prototype.parseResponse = function (fetchResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var rawResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchResponse.json()];
                    case 1:
                        rawResponse = _a.sent();
                        return [2 /*return*/, new ADXResponse(rawResponse)];
                }
            });
        });
    };
    return ADXClient;
}());
export { ADXClient };
