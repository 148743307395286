import Utils from "../Utils";
var HeatmapData = /** @class */ (function () {
    function HeatmapData(chartComponentData, aggKey) {
        var _this = this;
        this.visibleSBs = [];
        this.timeStamps = [];
        this.numRows = 0;
        this.numCols = 0;
        this.aggKey = aggKey;
        this.chartComponentData = chartComponentData;
        this.chartComponentData.isFromHeatmap = true;
        this.visibleSBs = Object.keys(this.chartComponentData.displayState[aggKey].splitBys).filter(function (sb) {
            return (_this.chartComponentData.getSplitByVisible(aggKey, sb));
        });
        this.numRows = this.visibleSBs.length;
        this.from = new Date(chartComponentData.displayState[aggKey].aggregateExpression.searchSpan.from);
        this.to = new Date(chartComponentData.displayState[aggKey].aggregateExpression.searchSpan.to);
        this.bucketSize = Utils.parseTimeInput(chartComponentData.displayState[aggKey].aggregateExpression.searchSpan.bucketSize);
        this.createTimeValues();
    }
    HeatmapData.prototype.adjustStartTime = function () {
        return new Date(Utils.adjustStartMillisToAbsoluteZero(new Date(this.from).valueOf(), this.bucketSize));
    };
    HeatmapData.prototype.createTimeValues = function () {
        var _this = this;
        this.timeValues = {};
        this.allValues = [];
        //turn time array into an object keyed by timestamp 
        var colI = 0;
        var adjustedStartTime = this.adjustStartTime();
        for (var currTime = adjustedStartTime; (currTime.valueOf() < this.to.valueOf()); currTime = new Date(currTime.valueOf() + this.bucketSize)) {
            this.timeValues[currTime.toISOString()] = this.visibleSBs.reduce(function (obj, splitBy, splitByI) {
                obj[splitBy] = {
                    colI: colI,
                    rowI: splitByI,
                    value: null
                };
                return obj;
            }, {});
            colI += 1;
        }
        this.numCols = Object.keys(this.timeValues).length;
        this.visibleSBs.forEach(function (splitBy, rowI) {
            _this.chartComponentData.timeArrays[_this.aggKey][splitBy].forEach(function (valueObject, colI) {
                var timestamp = new Date(valueObject.dateTime.valueOf()).toISOString();
                var visibleMeasure = _this.chartComponentData.getVisibleMeasure(_this.aggKey, splitBy);
                if (_this.timeValues[timestamp]) {
                    _this.timeValues[timestamp][splitBy].value = valueObject.measures ? valueObject.measures[visibleMeasure] : null;
                    if (Utils.safeNotNullOrUndefined(function () { return valueObject.measures[visibleMeasure]; }))
                        _this.allValues.push(valueObject.measures[visibleMeasure]);
                }
            });
        });
    };
    return HeatmapData;
}());
export { HeatmapData };
