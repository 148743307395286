var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Utils from "../Utils";
import { ChartDataOptions } from "./ChartDataOptions";
var MAXCARD = 150000;
var AggregateExpression = /** @class */ (function (_super) {
    __extends(AggregateExpression, _super);
    function AggregateExpression(predicateObject, measureObject, measureTypes, searchSpan, splitByObject, colorOrOptionsObject, alias, contextMenu) {
        if (splitByObject === void 0) { splitByObject = null; }
        var _this = _super.call(this, (typeof (colorOrOptionsObject) === 'object' && !!colorOrOptionsObject) ? __assign(__assign({}, colorOrOptionsObject), { searchSpan: searchSpan, measureTypes: measureTypes }) : { color: colorOrOptionsObject, searchSpan: searchSpan, measureTypes: measureTypes, alias: alias, contextMenu: contextMenu }) || this;
        _this.visibleSplitByCap = 10;
        _this.predicate = predicateObject;
        _this.splitByObject = splitByObject;
        _this.measureObject = ((measureTypes.length == 1 && measureTypes[0] == 'count') || measureObject.property == 'Events Count') ? { count: {} } : { input: measureObject };
        return _this;
    }
    AggregateExpression.prototype.toTsx = function (roundFromTo) {
        var _this = this;
        if (roundFromTo === void 0) { roundFromTo = false; }
        var tsx = {};
        var shiftMillis = Utils.parseShift(this.timeShift, this.startAt, this.searchSpan);
        var fromMillis = this.searchSpan.from.valueOf() + shiftMillis;
        var toMillis = this.searchSpan.to.valueOf() + shiftMillis;
        var bucketSizeInMillis = Utils.parseTimeInput(this.searchSpan.bucketSize);
        var roundedFromMillis = Math.floor((fromMillis + 62135596800000) / (bucketSizeInMillis)) * (bucketSizeInMillis) - 62135596800000;
        var roundedToMillis = Math.ceil((toMillis + 62135596800000) / (bucketSizeInMillis)) * (bucketSizeInMillis) - 62135596800000;
        if (roundFromTo) {
            fromMillis = roundedFromMillis;
            toMillis = roundedToMillis;
        }
        tsx['searchSpan'] = { from: (new Date(fromMillis)).toISOString(), to: (new Date(toMillis)).toISOString() };
        // create aggregates
        var measures = (this.measureObject.hasOwnProperty('count')) ? [{ count: {} }]
            : this.measureTypes.reduce(function (p, c) {
                var measureObject = {};
                if (c == 'count')
                    measureObject = { count: {} };
                else
                    measureObject[c] = _this['measureObject'];
                p.push(measureObject);
                return p;
            }, []);
        var aggregateObject = {};
        var dimensionObject = { dateHistogram: { input: { builtInProperty: "$ts" }, breaks: { size: this.searchSpan.bucketSize } } };
        if (this.splitByObject != null) {
            var bucketsCeil = Math.ceil((roundedToMillis - roundedFromMillis) / bucketSizeInMillis);
            aggregateObject['dimension'] = { uniqueValues: { input: this.splitByObject, take: Math.floor(MAXCARD / bucketsCeil) } };
            aggregateObject['aggregate'] = { dimension: dimensionObject, measures: measures };
        }
        else {
            aggregateObject['dimension'] = dimensionObject;
            aggregateObject['measures'] = measures;
        }
        var aggregates = [aggregateObject];
        tsx['aggregates'] = aggregates;
        // create predicate
        var predicate;
        if (!this.measureObject.hasOwnProperty('count'))
            predicate = { and: [this.predicate, { not: { eq: { left: this.measureObject.input, right: { 'double': null } } } }] };
        else
            predicate = this.predicate;
        tsx['predicate'] = predicate;
        return tsx;
    };
    return AggregateExpression;
}(ChartDataOptions));
export default AggregateExpression;
