var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import './EventsPlot.scss';
import { Plot } from '../../Interfaces/Plot';
import Utils from '../../Utils';
import { EventElementTypes, DataTypes } from "../../Constants/Enums";
import { NONNUMERICTOPMARGIN, LINECHARTTOPPADDING } from "../../Constants/Constants";
var EventsPlot = /** @class */ (function (_super) {
    __extends(EventsPlot, _super);
    function EventsPlot(svgSelection) {
        var _this = _super.call(this, svgSelection) || this;
        _this.gradientArray = {};
        _this.eventOnClick = function (d) {
            if (_this.chartDataOptions.onElementClick) {
                _this.chartDataOptions.onElementClick(d.aggregateKey, d.splitBy, d.dateTime.toISOString(), d.measures);
            }
        };
        _this.colorFunction = function (d) {
            if (d.measures) {
                if (Object.keys(d.measures).length === 1) {
                    return _this.getColorForValue(Object.keys(d.measures)[0]);
                }
                else {
                    return 'grey';
                }
            }
            return 'none';
        };
        _this.createDateStringFunction = function (shiftMillis) {
            return Utils.timeFormat(_this.chartComponentData.usesSeconds, _this.chartComponentData.usesMillis, _this.chartOptions.offset, _this.chartOptions.is24HourTime, shiftMillis, null, _this.chartOptions.dateLocale);
        };
        _this.createEventElements = function (splitBy, g, splitByIndex) {
            var sortEvents = function () {
                enteredEvents.sort(function (a, b) {
                    if (a.dateTime < b.dateTime) {
                        return -1;
                    }
                    else if (a.dateTime > b.dateTime) {
                        return 1;
                    }
                    return 0;
                });
            };
            var data = _this.chartComponentData.timeArrays[_this.aggKey][splitBy];
            var discreteEvents = g.selectAll(".tsi-discreteEvent")
                .data(data, function (d) { return d.dateTime; });
            var self = _this;
            var enteredEvents;
            var shiftMillis = _this.chartComponentData.getTemporalShiftMillis(_this.aggKey);
            var dateStringFn = _this.createDateStringFunction(shiftMillis);
            switch (_this.chartDataOptions.eventElementType) {
                case EventElementTypes.Teardrop:
                    if (discreteEvents.size() && discreteEvents.classed('tsi-discreteEventDiamond')) {
                        g.selectAll('.tsi-discreteEvent').remove();
                        discreteEvents = g.selectAll(".tsi-discreteEvent")
                            .data(data, function (d) { return d.dateTime; });
                    }
                    enteredEvents = discreteEvents.enter()
                        .append('path')
                        .attr('class', 'tsi-discreteEvent tsi-valueElement')
                        .merge(discreteEvents)
                        .classed('tsi-discreteEventDiamond', false)
                        .classed('tsi-discreteEventTeardrop', true)
                        .attr('transform', function (d) {
                        return 'translate(' + (_this.x(new Date(d.dateTime)) + _this.eventHeight / 2) + ',' + (_this.eventHeight * 1.4) + ') rotate(180)';
                    })
                        .attr('d', _this.teardropD(_this.eventHeight, _this.eventHeight))
                        .attr('stroke-width', Math.min(_this.eventHeight / 2.25, 8))
                        .attr('stroke', _this.colorFunction)
                        .attr('fill', 'none');
                    break;
                case EventElementTypes.Diamond:
                    if (discreteEvents.size() && discreteEvents.classed('tsi-discreteEventTeardrop')) {
                        g.selectAll('.tsi-discreteEvent').remove();
                        discreteEvents = g.selectAll(".tsi-discreteEvent")
                            .data(data, function (d) { return d.dateTime; });
                    }
                    enteredEvents = discreteEvents.enter()
                        .append('rect')
                        .attr('class', 'tsi-discreteEvent tsi-valueElement')
                        .merge(discreteEvents)
                        .classed('tsi-discreteEventTeardrop', false)
                        .classed('tsi-discreteEventDiamond', true)
                        .attr('d', 'none')
                        .attr('transform', function (d) {
                        return 'translate(' + _this.x(new Date(d.dateTime)) + ',0) rotate(45)';
                    })
                        .attr('fill', _this.colorFunction)
                        .attr('stroke', 'none');
                    break;
            }
            enteredEvents
                .attr('y', 0)
                .attr('x', 0)
                .attr('width', _this.eventHeight)
                .attr('height', _this.eventHeight)
                .on('mouseover', function (d) {
                d3.select(this).raise();
                self.onMouseover(d, splitByIndex);
            })
                .on('mouseout', function () {
                _this.onMouseout();
            })
                .on('click', function (d) {
                _this.eventOnClick(d);
            })
                .on('touchstart', function (d) {
                _this.eventOnClick(d);
            })
                .on('keydown', function (d) {
                if (d3.event.keyCode === 9) {
                    sortEvents();
                    d3.select(this).node().focus();
                }
                if (d3.event.keyCode === 32 || d3.event.keyCode === 13) {
                    self.eventOnClick(d);
                }
            })
                .attr('role', _this.chartDataOptions.onElementClick ? 'button' : null)
                .attr('tabindex', _this.chartDataOptions.onElementClick ? '0' : null)
                .attr('cursor', _this.chartDataOptions.onElementClick ? 'pointer' : 'inherit')
                .attr('aria-label', function (d) {
                if (_this.chartDataOptions.onElementClick) {
                    var dateString = dateStringFn(d);
                    var retString_1 = _this.getString('event in series') + " " + d.aggregateName + " " + _this.getString('at time') + " " + dateString + ".";
                    Object.keys(d.measures).forEach(function (mKey) {
                        retString_1 += " " + _this.getString('measure with key') + " " + mKey + " " + _this.getString('and value') + " " + d.measures[mKey] + ".";
                    });
                    return retString_1;
                }
                return null;
            })
                .style('visibility', function (d) {
                return (self.chartComponentData.isSplitByVisible(_this.aggKey, splitBy) && self.hasData(d)) ? 'visible' : 'hidden';
            })
                .each(function (d, i) {
                if (Object.keys(d.measures).length > 1) {
                    var gradientKey = self.createGradientKey(d, splitByIndex, i);
                    self.gradientData.push([gradientKey, d]);
                    d3.select(this)
                        .attr(self.chartDataOptions.eventElementType === EventElementTypes.Teardrop ? 'stroke' : 'fill', "url(#" + gradientKey + ")");
                }
            });
            discreteEvents.exit().remove();
        };
        _this.shouldDrawBackdrop = function () {
            //check to see if this is the first aggregate within a collapsed swimlane. 
            var lane = _this.chartComponentData.getSwimlane(_this.aggKey);
            if (!_this.chartOptions.swimLaneOptions || !_this.chartOptions.swimLaneOptions[lane] ||
                !_this.chartOptions.swimLaneOptions[lane].collapseEvents) {
                return true;
            }
            var eventSeriesInLane = Object.keys(_this.chartComponentData.displayState).filter(function (aggKey) {
                return _this.chartComponentData.getSwimlane(aggKey) === lane;
            });
            return eventSeriesInLane.indexOf(_this.aggKey) === 0;
        };
        _this.plotDataType = DataTypes.Events;
        return _this;
    }
    EventsPlot.prototype.onMouseover = function (d, seriesNumber) {
        var _this = this;
        var getX = function () {
            return _this.x(new Date(d.dateTime));
        };
        var seriesWidth = Math.ceil(this.eventHeight * Math.sqrt(2));
        var seriesTop = this.yTop + NONNUMERICTOPMARGIN + (seriesWidth * seriesNumber) + (seriesWidth / 2);
        var shouldMouseover = this.discreteEventsMouseover(d, getX() + (seriesWidth / 2), seriesTop, seriesWidth);
        if (!shouldMouseover) {
            return;
        }
        var visibleMeasures = this.getVisibleMeasures(d.measures);
        this.hoverLine.attr('visibility', 'visible')
            .attr('x1', getX)
            .attr('x2', getX)
            .attr('y1', LINECHARTTOPPADDING)
            .attr('y2', this.chartHeight + 1)
            .attr('stroke', function () {
            return visibleMeasures.length === 1 ? _this.getColorForValue(visibleMeasures[0]) : 'grey';
        });
    };
    EventsPlot.prototype.onMouseout = function () {
        this.hoverLine.attr('visibility', 'hidden');
        this.discreteEventsMouseout();
    };
    EventsPlot.prototype.createHoverLine = function () {
        if (!this.hoverLine) {
            this.hoverLine = this.chartGroup.append('line')
                .attr('class', 'tsi-discreteEventHoverLine')
                .attr('y1', LINECHARTTOPPADDING)
                .attr('y2', this.chartHeight + 1)
                .attr('pointer-events', 'none')
                .attr('visibility', 'hidden');
        }
        else {
            this.hoverLine.raise();
        }
    };
    EventsPlot.prototype.setEventHeight = function (visibleSeriesCount) {
        var useableHeight = this.height - NONNUMERICTOPMARGIN;
        this.eventHeight = Math.floor((useableHeight / visibleSeriesCount) / Math.sqrt(2));
    };
    EventsPlot.prototype.render = function (chartOptions, visibleAggI, agg, aggVisible, aggregateGroup, chartComponentData, yExtent, chartHeight, visibleAggCount, colorMap, previousAggregateData, x, areaPath, strokeOpacity, y, yMap, defs, chartDataOptions, previousIncludeDots, yTopAndHeight, chartGroup, discreteEventsMouseover, discreteEventsMouseout) {
        var _this = this;
        this.chartOptions = chartOptions;
        this.yTop = yTopAndHeight[0];
        this.height = yTopAndHeight[1];
        this.x = x;
        this.chartComponentData = chartComponentData;
        this.aggKey = agg.aggKey;
        this.chartDataOptions = chartDataOptions;
        this.chartHeight = chartHeight;
        this.chartGroup = chartGroup;
        this.aggregateGroup = aggregateGroup;
        this.discreteEventsMouseover = discreteEventsMouseover;
        this.discreteEventsMouseout = discreteEventsMouseout;
        this.createBackdropRect(this.shouldDrawBackdrop());
        if (this.aggregateGroup.selectAll('defs').empty()) {
            this.defs = this.aggregateGroup.append('defs');
        }
        this.createHoverLine();
        var series = this.getVisibleSeries(agg.aggKey);
        this.setEventHeight(series.length);
        if (this.aggregateGroup.selectAll('.tsi-splitBysGroup').empty()) {
            this.splitBysGroup = this.aggregateGroup.append('g').classed('tsi-splitBysGroup', true);
        }
        var self = this;
        var splitByGroups = this.splitBysGroup.selectAll(".tsi-splitByGroup")
            .data(series, function (d) {
            return d.splitBy;
        });
        this.gradientData = [];
        var enteredSplitByGroups = splitByGroups.enter()
            .append("g")
            .attr("class", "tsi-eventsGroup tsi-splitByGroup " + this.aggKey)
            .merge(splitByGroups)
            .attr('transform', function (d, i) {
            return 'translate(0,' + (+(i * (_this.chartDataOptions.height / series.length))) + ')';
        })
            .each(function (splitBy, j) {
            self.createEventElements(splitBy, d3.select(this), j);
        }).each(function () {
            self.themify(d3.select(this), self.chartOptions.theme);
        });
        splitByGroups.exit().remove();
        var gradients = this.defs.selectAll('linearGradient')
            .data(this.gradientData, function (d) {
            return d[1].splitBy;
        });
        var enteredGradients = gradients.enter()
            .append('linearGradient')
            .attr("x2", "0%")
            .attr("y2", "100%")
            .merge(gradients)
            .attr("id", function (d) { return d[0]; });
        enteredGradients
            .each(function (d) {
            self.addGradientStops(d[1], d3.select(this));
        });
        gradients.exit().remove();
    };
    return EventsPlot;
}(Plot));
export default EventsPlot;
