var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as d3 from "d3";
import "./ModelSearch.scss";
import Utils from "../../Utils";
import { Component } from "./../../Interfaces/Component";
import "awesomplete";
import Hierarchy from "../Hierarchy/Hierarchy";
import ModelAutocomplete from "../ModelAutocomplete/ModelAutocomplete";
var ModelSearch = /** @class */ (function (_super) {
    __extends(ModelSearch, _super);
    function ModelSearch(renderTarget, delegate) {
        var _this = _super.call(this, renderTarget) || this;
        _this.usedContinuationTokens = {};
        _this.currentResultIndex = -1;
        _this.stripHits = function (str) {
            return str
                .split("<hit>")
                .map(function (h) {
                return h
                    .split("</hit>")
                    .map(function (h2) { return Utils.strip(h2); })
                    .join("</hit>");
            })
                .join("<hit>");
        };
        _this.delegate = delegate;
        d3.select("html").on("click." + Utils.guid(), function () {
            if (_this.clickedInstance &&
                d3.event.target != _this.clickedInstance &&
                _this.contextMenu) {
                _this.closeContextMenu();
                _this.clickedInstance = null;
            }
        });
        return _this;
    }
    ModelSearch.prototype.ModelSearch = function () { };
    ModelSearch.prototype.render = function (hierarchyData, chartOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var self, continuationToken, searchText, targetElement, inputWrapper, autocompleteOnInput, modelAutocomplete, ap, results, noResults, instanceResultsWrapper, showMore, hierarchyElement, hierarchy, searchInstances, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.chartOptions.setOptions(chartOptions);
                        self = this;
                        targetElement = d3.select(this.renderTarget);
                        targetElement.html("");
                        this.wrapper = targetElement
                            .append("div")
                            .attr("class", "tsi-modelSearchWrapper");
                        _super.prototype.themify.call(this, this.wrapper, this.chartOptions.theme);
                        inputWrapper = this.wrapper
                            .append("div")
                            .attr("class", "tsi-modelSearchInputWrapper");
                        autocompleteOnInput = function (st, event) {
                            self.usedContinuationTokens = {};
                            // blow results away if no text
                            if (st.length === 0) {
                                searchText = st;
                                self.instanceResults.html("");
                                self.currentResultIndex = -1;
                                hierarchyElement.node().style.display = "block";
                                showMore.node().style.display = "none";
                                noResults.style("display", "none");
                            }
                            else if (event.which === 13 || event.keyCode === 13) {
                                hierarchyElement.node().style.display = "none";
                                self.instanceResults.html("");
                                self.currentResultIndex = -1;
                                noResults.style("display", "none");
                                searchInstances(st);
                                searchText = st;
                            }
                        };
                        modelAutocomplete = new ModelAutocomplete(inputWrapper.node(), this.delegate);
                        modelAutocomplete.render(__assign({ onInput: autocompleteOnInput, onKeydown: function (event, ap) {
                                _this.handleKeydown(event, ap);
                            } }, chartOptions));
                        ap = modelAutocomplete.ap;
                        results = this.wrapper
                            .append("div")
                            .attr("class", "tsi-modelSearchResults")
                            .on("scroll", function () {
                            self.closeContextMenu();
                            var that = this;
                            if (that.scrollTop + that.clientHeight + 150 >
                                self.instanceResults.node().clientHeight &&
                                searchText.length !== 0) {
                                searchInstances(searchText, continuationToken);
                            }
                        });
                        noResults = results
                            .append("div")
                            .text(this.getString("No results"))
                            .classed("tsi-noResults", true)
                            .style("display", "none");
                        instanceResultsWrapper = results
                            .append("div")
                            .attr("class", "tsi-modelSearchInstancesWrapper");
                        this.instanceResults = instanceResultsWrapper
                            .append("div")
                            .attr("class", "tsi-modelSearchInstances");
                        showMore = instanceResultsWrapper
                            .append("div")
                            .attr("class", "tsi-showMore")
                            .text(this.getString("Show more") + "...")
                            .on("click", function () { return searchInstances(searchText, continuationToken); })
                            .style("display", "none");
                        hierarchyElement = this.wrapper
                            .append("div")
                            .attr("class", "tsi-hierarchyWrapper");
                        hierarchy = new Hierarchy(hierarchyElement.node());
                        hierarchy.render(hierarchyData, __assign(__assign({}, this.chartOptions), { withContextMenu: true }));
                        searchInstances = function (searchText, ct) {
                            if (ct === void 0) { ct = null; }
                            return __awaiter(_this, void 0, void 0, function () {
                                var self, r, instances;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            self = this;
                                            if (ct === "END")
                                                return [2 /*return*/];
                                            if (!(ct === null || !self.usedContinuationTokens[ct])) return [3 /*break*/, 2];
                                            self.usedContinuationTokens[ct] = true;
                                            return [4 /*yield*/, this.delegate.getInstancesSearch(searchText)];
                                        case 1:
                                            r = _a.sent();
                                            instances = r.instances.hits;
                                            //ontinuationToken = r.instances.hits.continuationToken;
                                            if (!continuationToken)
                                                continuationToken = "END";
                                            showMore.node().style.display =
                                                continuationToken !== "END" ? "block" : "none";
                                            if (instances.length == 0) {
                                                noResults.style("display", "block");
                                            }
                                            else {
                                                noResults.style("display", "none");
                                            }
                                            instances.forEach(function (i) {
                                                var handleClick = function (elt, wrapperMousePos, eltMousePos, fromKeyboard) {
                                                    if (fromKeyboard === void 0) { fromKeyboard = false; }
                                                    self.closeContextMenu();
                                                    if (self.clickedInstance != elt) {
                                                        self.clickedInstance = elt;
                                                        i.type = self.types.filter(function (t) {
                                                            return (t.name.replace(/\s/g, "") ===
                                                                i.highlights.typeName
                                                                    .split("<hit>")
                                                                    .join("")
                                                                    .split("</hit>")
                                                                    .join("")
                                                                    .replace(/\s/g, ""));
                                                        })[0];
                                                        var contextMenuActions = self.chartOptions.onInstanceClick(i);
                                                        self.contextMenu = self.wrapper.append("div");
                                                        if (!Array.isArray(contextMenuActions)) {
                                                            contextMenuActions = [contextMenuActions];
                                                        }
                                                        var totalActionCount_1 = contextMenuActions
                                                            .map(function (cma) { return Object.keys(cma).length; })
                                                            .reduce(function (p, c) { return p + c; }, 0);
                                                        var currentActionIndex_1 = 0;
                                                        contextMenuActions.forEach(function (cma, cmaGroupIdx) {
                                                            Object.keys(cma).forEach(function (k, kIdx, kArray) {
                                                                var localActionIndex = currentActionIndex_1;
                                                                self.contextMenu
                                                                    .append("div")
                                                                    .text(k)
                                                                    .on("click", cma[k])
                                                                    .on("keydown", function () {
                                                                    var evt = d3.event;
                                                                    if (evt.keyCode === 13) {
                                                                        this.click();
                                                                    }
                                                                    if (evt.keyCode === 13 || evt.keyCode === 37) {
                                                                        self.closeContextMenu();
                                                                        var results_1 = self.instanceResults.selectAll(".tsi-modelResultWrapper");
                                                                        results_1.nodes()[self.currentResultIndex].focus();
                                                                    }
                                                                    if (evt.keyCode === 40 &&
                                                                        localActionIndex + 1 < totalActionCount_1) {
                                                                        // down
                                                                        self.contextMenu
                                                                            .node()
                                                                            .children[localActionIndex +
                                                                            1 +
                                                                            cmaGroupIdx +
                                                                            (kIdx === kArray.length - 1 ? 1 : 0)].focus();
                                                                    }
                                                                    if (evt.keyCode === 38 && localActionIndex > 0) {
                                                                        // up
                                                                        self.contextMenu
                                                                            .node()
                                                                            .children[localActionIndex -
                                                                            1 +
                                                                            cmaGroupIdx -
                                                                            (kIdx === 0 ? 1 : 0)].focus();
                                                                    }
                                                                })
                                                                    .attr("tabindex", "0");
                                                                currentActionIndex_1++;
                                                            });
                                                            self.contextMenu.append("div").classed("tsi-break", true);
                                                        });
                                                        self.contextMenu.attr("style", function () { return "top: " + (wrapperMousePos - eltMousePos) + "px"; });
                                                        self.contextMenu.classed("tsi-modelSearchContextMenu", true);
                                                        d3.select(elt).classed("tsi-resultSelected", true);
                                                        if (self.contextMenu.node().children.length > 0 && fromKeyboard) {
                                                            self.contextMenu.node().children[0].focus();
                                                        }
                                                    }
                                                    else {
                                                        self.clickedInstance = null;
                                                    }
                                                };
                                                _this.instanceResults
                                                    .append("div")
                                                    .html(self.getInstanceHtml(i)) // known unsafe usage of .html
                                                    .on("click", function () {
                                                    var mouseWrapper = d3.mouse(self.wrapper.node());
                                                    var mouseElt = d3.mouse(this);
                                                    handleClick(this, mouseWrapper[1], mouseElt[1]);
                                                })
                                                    .on("keydown", function () {
                                                    var evt = d3.event;
                                                    if (evt.keyCode === 13) {
                                                        var resultsNodes = _this.instanceResults
                                                            .selectAll(".tsi-modelResultWrapper")
                                                            .nodes();
                                                        var height = 0;
                                                        for (var i = 0; i < _this.currentResultIndex; i++) {
                                                            height += resultsNodes[0].clientHeight;
                                                        }
                                                        handleClick(_this.instanceResults
                                                            .select(".tsi-modelResultWrapper:focus")
                                                            .node(), height - results.node().scrollTop + 48, 0, true);
                                                    }
                                                    self.handleKeydown(evt, ap);
                                                })
                                                    .attr("tabindex", "0")
                                                    .classed("tsi-modelResultWrapper", true);
                                            });
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            });
                        };
                        _a = this;
                        return [4 /*yield*/, this.delegate.getHierarchies()];
                    case 1:
                        _a.hierarchies = _c.sent();
                        // get types
                        _b = this;
                        return [4 /*yield*/, this.delegate.getTimeSeriesTypes()];
                    case 2:
                        // get types
                        _b.types = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModelSearch.prototype.handleKeydown = function (event, ap) {
        if (!ap.isOpened) {
            var results = this.instanceResults.selectAll(".tsi-modelResultWrapper");
            if (results.size()) {
                if (event.keyCode === 40 &&
                    this.currentResultIndex < results.nodes().length - 1) {
                    this.currentResultIndex++;
                    results.nodes()[this.currentResultIndex].focus();
                }
                else if (event.keyCode === 38) {
                    this.currentResultIndex--;
                    if (this.currentResultIndex <= -1) {
                        this.currentResultIndex = -1;
                        ap.input.focus();
                    }
                    else {
                        results.nodes()[this.currentResultIndex].focus();
                    }
                }
            }
        }
    };
    ModelSearch.prototype.closeContextMenu = function () {
        if (this.contextMenu) {
            this.contextMenu.remove();
        }
        d3.selectAll(".tsi-resultSelected").classed("tsi-resultSelected", false);
    };
    ModelSearch.prototype.getInstanceHtml = function (i) {
        var _this = this;
        return "<div class=\"tsi-modelResult\">\n                    <div class=\"tsi-modelPK\">\n                        " + (i.highlights.name
            ? this.stripHits(i.highlights.name)
            : this.stripHits(i.highlights.timeSeriesIds
                ? i.highlights.timeSeriesIds.join(" ")
                : i.highlights.timeSeriesId.join(" "))) + "\n                    </div>\n                    <div class=\"tsi-modelHighlights\">\n                        " + this.stripHits(i.highlights.description &&
            i.highlights.description.length
            ? i.highlights.description
            : this.getString("No description")) + "\n                        <br/><table>\n                        " + (i.highlights.name
            ? "<tr><td>" +
                this.getString("Time Series ID") +
                "</td><td>" +
                this.stripHits(i.highlights.timeSeriesIds
                    ? i.highlights.timeSeriesIds.join(" ")
                    : i.highlights.timeSeriesId.join(" ")) +
                "</td></tr>"
            : "") + "                        \n                        " + i.highlights.instanceFieldNames
            .map(function (ifn, idx) {
            var val = i.highlights.instanceFieldValues[idx];
            if (ifn.indexOf("<hit>") !== -1 ||
                val.indexOf("<hit>") !== -1) {
                return val.length === 0
                    ? ""
                    : "<tr><td>" +
                        _this.stripHits(ifn) +
                        "</td><td>" +
                        _this.stripHits(val) +
                        "</tr>";
            }
        })
            .join("") + "\n                        </table>\n                    </div>\n                </div>";
    };
    return ModelSearch;
}(Component));
export default ModelSearch;
