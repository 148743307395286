var TimeSeriesEventCell = /** @class */ (function () {
    function TimeSeriesEventCell(name, value, type) {
        if (type === void 0) { type = null; }
        this.key = name + "_" + type;
        this.name = name;
        this.type = type;
        this.value = value;
    }
    return TimeSeriesEventCell;
}());
export { TimeSeriesEventCell };
